<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between align-center pr-4">
        <p class="text-h5 font-weight-medium mb-0">
          Historial de Donaciones de Donantes Frecuentes
        </p>
        <v-spacer></v-spacer>
        <div>
          <div>
            <p class="mb-0 text-caption"><b>Filtros</b></p>
          </div>
          <v-row>
            <v-col cols="12" md="6" class="pt-0">
              <v-select
                label="Seleccionar Grupo"
                hide-details=""
                :items="groups"
                item-text="group"
                item-value="id_group"
                v-model="search.filter"
                @change="getTransactions"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 my-0">
              <v-select
                label="Seleccione año lectivo"
                hide-details=""
                :items="years"
                item-text="year"
                item-value="year"
                v-model="search.year"
                @change="getTransactions"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-banner>
    <v-container>
      <v-row>
        <v-col>
          <v-card class="">
            <v-card-text>
              <v-data-table
                :headers="data.headers"
                :items="data.data"
                :items-per-page="5"
                :search="search.search"
                :loading="data.load"
                loading-text="Cargando datos..."
                class="elevation-0 border-solid"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title class="text-h5">
                      Listado de Donantes Frecuentes
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field
                      label="Buscar..."
                      v-model="search.search"
                      append-icon="mdi-magnify"
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            <vue-json-to-csv
                              :json-data="csvData"
                              :labels="labelsData"
                              :disabled="data.data.length == 0"
                              csv-title="Lista De Transacciones"
                              class="black--text"
                            >
                              <v-icon color="green">mdi-file-excel</v-icon>
                              Descargar CSV
                            </vue-json-to-csv>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                </template>
                <!-- Custom columns -->
                <!-- Si no carga la data -->
                <template v-slot:no-data>
                  <v-btn color="primary" @click="getTransactions">
                    Recargar
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueJsonToCsv from 'vue-json-to-csv';
export default {
  name: 'FrecuentDonors',
  components: {
    VueJsonToCsv,
  },
  data: () => ({
    data: {
      load: false,
      headers: [
        { text: 'Donante Frecuente', value: 'name' },
        { text: 'Promesa de Donación', value: 'goal_group' },
        { text: 'Ene', value: 'meses[0].ene' },
        { text: 'Feb', value: 'meses[1].feb' },
        { text: 'Mar', value: 'meses[2].mar' },
        { text: 'Abr', value: 'meses[3].abr' },
        { text: 'May', value: 'meses[4].may' },
        { text: 'Jun', value: 'meses[5].jun' },
        { text: 'Jul', value: 'meses[6].jul' },
        { text: 'Ago', value: 'meses[7].ago' },
        { text: 'Sep', value: 'meses[8].sep' },
        { text: 'Oct', value: 'meses[9].oct' },
        { text: 'Nov', value: 'meses[10].nov' },
        { text: 'Dic', value: 'meses[11].dic' },
        { text: 'Total donado en el año', value: 'total_donated_year' },
      ],
      data: [],
    },
    groups: [],
    years: [],
    search: {
      search: '',
      filter: 0,
      year: -1,
    },
  }),
  mounted() {
    this.getGroupsByCoordinator();
    this.getYears();
  },
  methods: {
    ...mapActions({
      openSnackbar: 'Snackbar/openSnackbar',
    }),

    getGroupsByCoordinator: async function() {
      await this.$http
        .get(this.api + 'donor_group/all/groups_donor')
        .then(({ data }) => {
          this.groups = data;
          if (this.groups.length > 0) {
            this.search.filter = this.groups[0].id_group;
            this.getTransactions();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al obtener Grupos',
          });
        });
    },

    getYears: async function() {
      await this.$http
        .get(this.api + 'tx/years')
        .then(({ data }) => {
          this.years = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al obtener Años lectivos',
          });
        });
    },

    getTransactions: async function() {
      let group = this.search.filter;
      let year = this.search.year;
      let url = 'tx/all_frequent_donor/';
      if (group != 0) {
        url = 'tx/all_frequent_donor/' + group;
      }
      if (year >= 0) {
        url = `tx/all_frequent_donor/${group}/${year}`;
      }
      this.data.load = true;
      await this.$http
        .get(this.api + url)
        .then(({ data }) => {
          data.forEach((item) => {
            if (item.goal_group != null) {
              item.goal_group = '$ ' + parseFloat(item.goal_group).toFixed(2);
            } else {
              item.goal_group = '$ ' + parseFloat(0).toFixed(2);
            }
            if (item.total_donated_year != null) {
              item.total_donated_year =
                '$ ' + parseFloat(item.total_donated_year).toFixed(2);
            } else {
              item.total_donated_year = '$ ' + parseFloat(0).toFixed(2);
            }
            // Meses
            for (let i = 0; i < item.meses.length; i++) {
              for (var [key, value] of Object.entries(item.meses[i])) {
                if (item.meses[i][key] == null) {
                  item.meses[i][key] = '$ ' + parseFloat(0).toFixed(2);
                } else {
                  item.meses[i][key] = '$ ' + parseFloat(value).toFixed(2);
                }
              }
            }
          });
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al obtener Transacciones por grupo',
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
    }),

    labelsData() {
      let headers = {};
      let localHeaders = this.data.headers;
      if (localHeaders.length > 0) {
        localHeaders.forEach((header) => {
          let excluir = [];
          if (!this.searchInArray(excluir, header.text)) {
            headers[header.value] = { title: header.text };
          }
        });
      }
      return headers;
    },

    csvData() {
      let data = [];
      //de esta manera le quito la reactividad a la variables
      let localDataJSON = JSON.stringify(this.data.data);
      let localdata = JSON.parse(localDataJSON);
      //transformando data
      if (localdata.length > 0) {
        localdata.forEach((item) => {
          data.push(item);
        });
      } else {
        data.push({});
      }
      return data;
    },
  },
};
</script>

<style></style>
